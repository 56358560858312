import React from "react"
import SEO from '../../components/seo'

import Osirase from '../../layouts/osirase'

export default () => (


  <Osirase>

  	<SEO title="イマイチだった"/>

	<h1>教えてくれてありがとうございました。</h1>

	<p>教えていただいて嬉しいです。<br/>
	自分を改めていきます。</p>

  </Osirase>
)
